<template>
  <div class="app">
    <div class="header">
      <div class="logo-wrap">
        <img class="logo" src="@/assets/images/logo.jpg"/>
        <div class="title">选地接</div>
      </div>
    </div>
    <div class="main">
      <div class="content">
        <div class="slogan"><span>美好旅行，</span><span>从选地接开始</span></div>
        <div class="description">[选地接]是专门发现目的地优质景区、地接、酒店、民宿、玩乐体验等旅游资源的信息服务平台，美好旅行，从[选地接]开始。</div>
        <div class="wechat-wrap">
          <div class="wechat">
            <a-image width="16rem" src="https://xuandijie.oss-cn-shanghai.aliyuncs.com/qrcode--mp.jpg"/>
            <div class="wechat__text">扫码查看全球旅游资源</div>
          </div>
          <div class="wechat">
            <a-image width="16rem" src="https://xuandijie.oss-cn-shanghai.aliyuncs.com/qrcode.jpg"/>
            <div class="wechat__text">扫码进入微信小程序</div>
          </div>
        </div>
      </div>
      <div class="footer">
        <div class="about">
          <div class="about-item">
            <div class="about-item__title">关于我们</div>
            <div class="about-item__content">[选地接]是谱见文旅旗下专门发现、推荐目的地优质地接、酒店、民宿、景区、玩乐等旅游资源的信息服务平台，长期专注提高目的地资源端和流量端连接效率，美好旅行，从选地接开始。</div>
          </div>
          <div class="about-item">
            <div class="about-item__title">加入我们</div>
            <div class="about-item__content">
              <span>岗位查看请关注公众号：</span>
              <span>谱见文旅，简历投递发送至：pujianwenlv@126.com</span>
            </div>
          </div>
          <div class="about-item">
            <div class="about-item__title">商务合作</div>
            <div class="about-item__content">商务合作、资源推广、目的地营销、目的地推广请将内容与需求发送至 pujianwenlv@126.com</div>
          </div>
        </div>
        <div class="copy">
          <span class="copy-item">沪ICP备2021013153号-1</span>
          <span class="copy-item">Copyright © 2021 选地接</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {
  },
  setup () {
  }
}
</script>
<style scoped>
.app {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: url('https://xuandijie.oss-cn-shanghai.aliyuncs.com/bg-home.jpeg') !important;
  background-size: cover;
  width: 100%;
  min-height: 100vh;
}
.header {
  position: absolute;
  top: 0;
  width: 100%;
  height: 6.25rem;
  box-sizing: border-box;
  padding: 1rem;
}
.logo-wrap {
  display: flex;
  align-items: center;
}
.logo {
  width: 3rem;
  height: 3rem;
  border-radius: 0.5rem;
  overflow: hidden;
}
.title {
  margin-left: 0.75rem;
  /* color: var(--primary-color); */
  font-size: 1.5rem;
  font-weight: 1000;
  color: #fff;
  font-family: fangsong;
}
.main {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  min-height: 100vh;
}
.content {
  padding-top: 14rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 100%;
}
.slogan {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  max-width: 90%;
  font-size: 4rem;
  line-height: 4.5rem;
  font-family: fangsong;
  font-weight: 800;
  /* font-style: italic; */
  color: white;
  text-shadow: 0 0.375rem 1.25rem rgb(0 0 0 / 50%);
}
.wechat-wrap {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 2rem;
}
.wechat {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem 0;
  color: #fff;
  font-size: 1.25rem;
  width: 22.5rem;
}
.wechat__img {
  width: 16rem;
  height: 16rem;
}
.wechat__text {
  margin-top: 0.75rem;
  font-family: fangsong;
  font-weight: 1000;
  font-size: 1.5rem;

}
.description {
  margin-top: 0.75rem;
  padding: 0 2rem;
  box-sizing: border-box;
  font-family: fangsong;
  font-weight: 1000;
  font-size: 1.5rem;
  color: #fff;
}
.footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  background: #333;
  overflow: hidden;
}
.about {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  width: 100%;
  padding: 1rem 1rem 3rem 1rem;
  box-sizing: border-box;
}
.about-item {
  display: flex;
  flex-direction: column;
  width: 30%;
}
.about-item__title {
  color: #fff;
  font-size: 1.25rem;
  font-weight: bold;
}
.about-item__content {
  margin-top: 0.5rem;
  color: #ccc;
  font-size: .875rem;
  word-break: break-all;
}
.copy {
  position: absolute;
  bottom: 0.25rem;
  color: #666;
}
.copy-item {
  padding: 0 0.5rem;
  font-size: 0.5rem;
}
</style>
